import React, { useState, useEffect } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, InputLabel, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuth } from './auth';

const PayoutOperations = () => {
  const [payouts, setPayouts] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [ibanFilter, setIbanFilter] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [totalDonations, setTotalDonations] = useState(0);
  const { token } = useAuth();
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;

  useEffect(() => {
    fetchPayoutOperations();
    fetchTotalDonations();
  }, [statusFilter, dateFilter, ibanFilter]);

  const fetchPayoutOperations = async () => {
    try {
      const params = {
        iban: ibanFilter || undefined,
        status: statusFilter || undefined,
        date: dateFilter || undefined
      };

      const response = await axios.get(`${apiUrl}/api/iban-payouts`, { 
        params,
        headers: {
          Authorization: `Bearer ${token}`
        } 
      });
      setPayouts(response.data);
    } catch (error) {
      setErrorMessage('Failed to load payout operations');
    }
  };

  const fetchTotalDonations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/admin/settings/total`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTotalDonations(response.data);
    } catch (error) {
      setErrorMessage('Failed to load total donations');
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.put(
        `${apiUrl}/api/iban-payouts/${id}`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchPayoutOperations(); // Refresh the list after the status update
    } catch (error) {
      setErrorMessage('Failed to update status');
    }
  };

  const handleWithdraw = async () => {
    try {
      await axios.post(`${apiUrl}/admin/settings/withdrawDonations`, null, {
        params: { amount: withdrawAmount },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setWithdrawAmount(''); // Clear the input field
      fetchTotalDonations(); // Refresh the total donations after withdrawal
    } catch (error) {
      setErrorMessage('Failed to withdraw donations');
    }
  };

  return (
    <Container>
      <h2>Payout Operations</h2>
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            label="Status"
          >
            <MenuItem value=''>All</MenuItem>
            <MenuItem value='PENDING'>Pending</MenuItem>
            <MenuItem value='SUCCESS'>Success</MenuItem>
            <MenuItem value='REJECTED'>Rejected</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Filter by Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          sx={{ minWidth: 200 }}
        />
        <TextField
          label="Filter by IBAN"
          variant="outlined"
          value={ibanFilter}
          onChange={(e) => setIbanFilter(e.target.value)}
          sx={{ minWidth: 300 }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>IBAN</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts.map((payout) => (
              <TableRow key={payout.id}>
                <TableCell>{payout.id}</TableCell>
                <TableCell>{payout.amount}</TableCell>
                <TableCell>{payout.iban}</TableCell>
                <TableCell>{payout.status}</TableCell>
                <TableCell>{format(new Date(payout.createdAt), 'yyyy-MM-dd HH:mm')}</TableCell>
                <TableCell>
                  {payout.status === 'PENDING' && (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleStatusChange(payout.id, 'SUCCESS')}
                        sx={{ mr: 1 }}
                      >
                        Confirm
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleStatusChange(payout.id, 'REJECTED')}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Total Donations: {totalDonations.toFixed(2)} CHF
        </Typography>
        <TextField
          label="Withdraw Amount"
          type="number"
          variant="outlined"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          sx={{ mr: 2, minWidth: 200 }}
        />
        <Button variant="contained" color="primary" onClick={handleWithdraw}>
          Withdraw
        </Button>
      </Box>
    </Container>
  );
};

export default PayoutOperations;

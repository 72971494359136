import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Ensure the import is correct

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const logout = useCallback(async () => {
    try {
      console.log('Logging out user with token:', token);
      const response = await fetch(`${apiUrl}/api/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        localStorage.removeItem('token');
        setToken(null);
        setUserEmail(null);
        console.log('Logout successful, token removed');
        navigate('/login');
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
      localStorage.removeItem('token');
      setToken(null);
      setUserEmail(null);
      navigate('/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    if (token) {
      try {
        console.log('Token found, attempting to decode:', token);
        const decodedToken = jwtDecode(token);
        console.log('Decoded token:', decodedToken);

        if (decodedToken.exp * 1000 < Date.now()) {
          console.log('Token expired, logging out...');
          logout();
        } else {
          setUserEmail(decodedToken.sub);
          console.log('Token is valid, setting userEmail:', decodedToken.sub);
        }
      } catch (error) {
        console.error('Token decoding failed, logging out:', error);
        logout();
      }
    } else if (!['/login', '/reset-password'].includes(location.pathname)) {
      console.log('No token found, redirecting to login');
      navigate('/login');
    }
  }, [token, navigate, logout, location.pathname]);

  const login = (newToken) => {
    console.log('Logging in with token:', newToken);
    try {
      const decodedToken = jwtDecode(newToken);
      console.log('Token decoded during login:', decodedToken);

      // Check if the user has the required role
      const roles = decodedToken.role.map(role => role.name); // Extract role names
      if (!roles.includes('ROLE_USER')) { // Adjust based on your required role
        console.error('User does not have the required role. Access denied.');
        alert('Access denied. You do not have the required permissions.');
        return;
      }

      // If the user has the correct role, proceed with login
      localStorage.setItem('token', newToken);
      setToken(newToken);
      setUserEmail(decodedToken.sub);
      navigate('/');
    } catch (error) {
      console.error('Failed to decode token during login:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ token, userEmail, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

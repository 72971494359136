import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './auth';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import logo from './assets/Icon-nova.png';

const Navbar = () => {
  const { token, logout } = useAuth();
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} width={40} height={40} style={{ marginRight: '10px' }} alt="Logo" />
            Nova Metrics
          </div>
        </Typography>
        <IconButton 
          color="inherit" 
          onClick={handleMenuOpen} 
          aria-label="Select Language"
        >
          <LanguageIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
          <MenuItem onClick={() => handleLanguageChange('fr')}>Français</MenuItem>
          <MenuItem onClick={() => handleLanguageChange('de')}>Deutsch</MenuItem>
          <MenuItem onClick={() => handleLanguageChange('it')}>Italiano</MenuItem>
        </Menu>
        {token ? (
          <>
            <Button color="inherit" component={Link} to="/">
              {t('home')}
            </Button>
            <Button color="inherit" component={Link} to="/questions">
              {t('questions')}
            </Button>
            <Button color="inherit" component={Link} to="/users">
              {t('users')}
            </Button>
            <Button color="inherit" component={Link} to="/payouts">
              {t('payout operations')}
            </Button>
            <Button color="inherit" component={Link} to="/deals">
              {t('deals')}
            </Button>
            <Button color="inherit" component={Link} to="/announcements">
              {t('Announcements')}
            </Button>
            <Button color="inherit" onClick={logout}>
              {t('logout')}
            </Button>
          </>
        ) : (
          <Button color="inherit" component={Link} to="/login">
            {t('login')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

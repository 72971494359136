import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useAuth } from './auth';

const Announcements = () => {
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;
  const { token } = useAuth(); // Retrieve the token using a custom hook

  const [announcements, setAnnouncements] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [currentAnnouncement, setCurrentAnnouncement] = useState({
    id: '',
    translations: {
      en: { title: '', message: '' },
      fr: { title: '', message: '' },
      de: { title: '', message: '' },
      it: { title: '', message: '' },
    },
  });

  const handleLanguageChange = (event, newValue) => {
    setCurrentLanguage(newValue);
  };

  // Fetch announcements
  const fetchAnnouncements = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/announcements/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements', error);
    } finally {
      setLoading(false);
    }
  }, [token, apiUrl]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleOpen = (announcement = null) => {
    if (announcement) {
      const translations = {
        en: { title: '', message: '' },
        fr: { title: '', message: '' },
        de: { title: '', message: '' },
        it: { title: '', message: '' },
      };

      announcement.translations.forEach((t) => {
        translations[t.language] = {
          title: t.title,
          message: t.message,
        };
      });

      setCurrentAnnouncement({
        id: announcement.id,
        translations,
      });
    } else {
      setCurrentAnnouncement({
        id: '',
        translations: {
          en: { title: '', message: '' },
          fr: { title: '', message: '' },
          de: { title: '', message: '' },
          it: { title: '', message: '' },
        },
      });
    }
    setImageFile(null); // Reset image file input
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentAnnouncement((prevAnnouncement) => ({
      ...prevAnnouncement,
      translations: {
        ...prevAnnouncement.translations,
        [currentLanguage]: {
          ...prevAnnouncement.translations[currentLanguage],
          [name]: value,
        },
      },
    }));
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/announcements/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchAnnouncements();
    } catch (error) {
      console.error('Error deleting announcement', error);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    const announcementData = {
      title: currentAnnouncement.translations.en.title,
      message: currentAnnouncement.translations.en.message,
    };

    formData.append('announcement', JSON.stringify(announcementData));

    formData.append('englishTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.en.title,
      message: currentAnnouncement.translations.en.message,
      language: 'en'
    })], { type: 'application/json' }));

    formData.append('frenchTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.fr.title,
      message: currentAnnouncement.translations.fr.message,
      language: 'fr'
    })], { type: 'application/json' }));

    formData.append('italianTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.it.title,
      message: currentAnnouncement.translations.it.message,
      language: 'it'
    })], { type: 'application/json' }));

    formData.append('germanTranslation', new Blob([JSON.stringify({
      title: currentAnnouncement.translations.de.title,
      message: currentAnnouncement.translations.de.message,
      language: 'de'
    })], { type: 'application/json' }));

    if (imageFile) {
      formData.append('imageFile', imageFile);
    }

    try {
      if (currentAnnouncement.id) {
        // Update announcement
        await axios.put(`${apiUrl}/api/announcements/update/${currentAnnouncement.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        // Create a new announcement
        await axios.post(`${apiUrl}/api/announcements/create`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      fetchAnnouncements();
      handleClose();
    } catch (error) {
      console.error('Error saving announcement', error);
    }
  };

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={() => handleOpen()}>
        Add Announcement
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Create By</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {announcements.map((announcement) => {
                const englishTranslation = announcement.translations.find((t) => t.language === 'en') || {};
                return (
                  <TableRow key={announcement.id}>
                    <TableCell>{englishTranslation.title}</TableCell>
                    <TableCell>{englishTranslation.message}</TableCell>
                    <TableCell>{announcement.createdBy}</TableCell>
                    <TableCell>{new Date(announcement.createdAt).toLocaleString()}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpen(announcement)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(announcement.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentAnnouncement.id ? 'Edit Announcement' : 'Add Announcement'}</DialogTitle>
        <DialogContent>
          <Tabs
            value={currentLanguage}
            onChange={handleLanguageChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="English" value="en" />
            <Tab label="Français" value="fr" />
            <Tab label="Deutsch" value="de" />
            <Tab label="Italiano" value="it" />
          </Tabs>
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            name="title"
            value={currentAnnouncement.translations[currentLanguage].title}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Message"
            type="text"
            fullWidth
            name="message"
            value={currentAnnouncement.translations[currentLanguage].message}
            onChange={handleChange}
          />
          <Button
            variant="outlined"
            component="label"
            fullWidth
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            Upload Image
            <input
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {imageFile && (
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <img
                src={URL.createObjectURL(imageFile)}
                alt="Image Preview"
                style={{ maxWidth: '100%', maxHeight: '150px' }}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {currentAnnouncement.id ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Announcements;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useAuth } from './auth';

const Users = () => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    id: '',
    name: '',
    lastName: '',
    email: '',
    city: '',
    country: '',
    zipCode: '',
    address: '',
    phone: '',
    dateOfBirth: '',
    gender: '',
  });
  const apiUrl = process.env.REACT_APP_BASE_USER_SERVER;

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users', error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user', error);
    }
  };

  const handleOpen = (user = null) => {
    if (user) {
      setCurrentUser({
        ...user,
        dateOfBirth: user.dateOfBirth ? user.dateOfBirth.split('T')[0] : '', // Format YYYY-MM-DD
      });
    } else {
      setCurrentUser({
        id: '',
        name: '',
        lastName: '',
        email: '',
        city: '',
        country: '',
        zipCode: '',
        address: '',
        phone: '',
        dateOfBirth: '',
        gender: '',
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (currentUser.id) {
        await axios.put(`${apiUrl}/api/users/${currentUser.id}`, currentUser, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await axios.post(`${apiUrl}/api/users`, currentUser, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error('Error saving user', error);
    }
  };

  return (
    <Container>
      <Button variant="outlined" color="primary" onClick={() => handleOpen()}>
        Add User
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Date Of Birth</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.dateOfBirth}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.city}</TableCell>
                  <TableCell>{user.country}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(user)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentUser.id ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To {currentUser.id ? 'edit' : 'add'} a user, please fill in the form below.
          </DialogContentText>
          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            name="name"
            value={currentUser.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            name="lastName"
            value={currentUser.lastName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            name="email"
            value={currentUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="City"
            type="text"
            fullWidth
            name="city"
            value={currentUser.city}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Country"
            type="text"
            fullWidth
            name="country"
            value={currentUser.country}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Zip Code"
            type="text"
            fullWidth
            name="zipCode"
            value={currentUser.zipCode}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Address"
            type="text"
            fullWidth
            name="address"
            value={currentUser.address}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Phone"
            type="text"
            fullWidth
            name="phone"
            value={currentUser.phone}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Date of Birth"
            type="date"
            fullWidth
            name="dateOfBirth"
            value={currentUser.dateOfBirth}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Gender"
            type="text"
            fullWidth
            name="gender"
            value={currentUser.gender}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {currentUser.id ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Users;

// src/Home.js
import React from 'react';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('welcome')}
      </Typography>
    </Container>
  );
};

export default Home;
